import React, { useState, useEffect, useRef } from 'react';
import MainVideo from '../components/MainVideo';
// import { QRCode } from 'qrcode.react';

function Info({ request_id }) {
  const [textBlob, setTextBlob] = useState('');
  const [jsonData, setJsonData] = useState({});
  const [imageUrls, setImageUrls] = useState([]);
  const [narrationText, setNarrationText] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [projectUrlPrefix, setProjectUrlPrefix] = useState('');
  const audioRef = useRef();
  const videoRef = useRef();

  // Function to fetch data
  const fetchData = async () => {
    // const apiKey = process.env.REACT_APP_API_KEY;
    // const headers = {
    //   'Authorization': `Bearer ${apiKey}`
    // };

    try {
      // Fetch text blob
      const raw_response = await fetch(`https://api.scantounderstand.com/Stage/summary?request_id=${request_id}`);
      const response_data = await raw_response.json();
      setTextBlob(response_data.input_prompt);
      setJsonData(response_data.full_scenes_obj);
      setImageUrls(response_data.image_keys);
      setVideoFile(response_data.video);
      setAudioFile(response_data.narrator_audio);
      setProjectUrlPrefix(response_data.s3_project_prefix);
      setNarrationText(response_data.narration);

    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ height: '100vh', overflow: 'auto', padding: 20 }}>
      {/* Text blob with scroll */}
      <h2>Prompt</h2>
      <div style={{ height: '40vh', overflowY: 'scroll', padding: 20, border: '2px solid #ccc', marginBottom: 20, whiteSpace: 'pre-wrap' }}>
        {textBlob}
      </div>

      {/* JSON formatted text blob */}
      <h2>GenAI Ouptut</h2>
      <div style={{ height: '40vh', overflowY: 'scroll', padding: 20, border: '2px solid #ccc', marginBottom: 20, backgroundColor: '#f5f5f5', color: '#333', fontFamily: 'Monaco, monospace', whiteSpace: 'pre-wrap' }}>
        {JSON.stringify(jsonData, null, 2)}
      </div>

      {/* Images */}
      <section>
        <h2>GenAI Images</h2>
        <div style={{
          display: 'flex',
          flexWrap: 'wrap', // Enable wrapping of items
          justifyContent: 'space-around',
          padding: 20
        }}>
          {imageUrls.map((url, index) => (
            <img 
              key={index} 
              src={`https://scantounderstand.com/media/${projectUrlPrefix}/${url}`} 
              alt={`Description for image ${index + 1}`} 
              style={{ 
                width: '200px', 
                height: '200px', 
                marginBottom: '10px' // Optional: add some space below each image
              }} 
            />
          ))}
        </div>
      </section>

      <h2>Narration</h2>
      <div style={{ height: '30vh', overflowY: 'scroll', padding: 20, border: '2px solid #ccc', marginBottom: 20, backgroundColor: '#f5f5f5', color: '#333', fontFamily: 'Monaco, monospace', whiteSpace: 'pre-wrap' }}>
        {narrationText}
      </div>

      <h2>Narrator Audio</h2>
      <div style={{ padding: 20, marginBottom: 20 }}>
        <audio controls ref={audioRef} key={`/media/${projectUrlPrefix}/${audioFile}`}>
          <source src={`/media/${projectUrlPrefix}/${audioFile}`} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>

      {/* Video */}
      <h2>Putting it all together</h2>
      <MainVideo videoFile={`/media/${projectUrlPrefix}/${videoFile}`} videoRef={videoRef} />

      {/* QR Code */}
      {/* <div style={{ padding: 20 }}>
        <QRCode value="https://www.example.com" />
      </div> */}
    </div>
  );
}

export default Info;
