import React from 'react';

const TextSection = ({ title, content }) => {
  return (
    <section style={{ margin: '2rem 0' }}>
      <h2>{title}</h2>
      <p>{content}</p>
    </section>
  );
};

export default TextSection;
