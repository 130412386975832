import React from 'react';
import ReactDOM from 'react-dom';

import MainVideo from '../components/MainVideo';
import TextSection from '../components/TextSection';
import video from '../video/type2helmet.mp4';


const Type2Helmet = () => {
  return (
    <div>
      <main role="main" className="container">
        <MainVideo videoFile={video} />
        <TextSection 
          title="Guarding the Brain, Our Most Vital Tool"
          content="The human brain, while protected naturally by the skull, is vulnerable to severe injuries in industrial environments where the risks exceed everyday hazards. Type II safety helmets, as recommended by ANSI/ISEA Z89.1-2014 (R2019), are essential for workers as they offer 360-degree protection against impact, including side and rear impacts not addressed by traditional hard hats. These helmets incorporate advanced materials and shock-absorbing technologies, significantly reducing the risk of traumatic brain injuries, particularly in high-risk areas like construction sites. Ensuring that workers are equipped with helmets that meet these stringent standards is not just about compliance; it's about valuing and protecting the most critical asset in any operation: the human mind." 
        />
        <TextSection 
          title="Adapting to Challenges: Why Type II Safety Helmets Are Indispensable on Modern Work Sites"
          content="Modern work environments present a variety of challenges, from electrical hazards to the threat of falling or flying objects. Type II safety helmets are designed to withstand these diverse conditions, offering features such as electrical insulation, resistance to water, and slow burning in case of fire, as outlined by ANSI/ISEA Z89.1-2014 (R2019). This adaptability ensures that workers remain protected regardless of the specific dangers of their work site, from construction zones to electrical maintenance areas. By wearing helmets that are rigorously tested to meet these comprehensive standards, workers not only comply with OSHA requirements but also gain a significant advantage in maintaining their safety and well-being in the face of occupational hazards." 
        />
         <TextSection 
          title="Investing in Safety: The Long-term Benefits of Type II Safety Helmets"
          content="The adoption of Type II safety helmets signifies more than just an upgrade in personal protective equipment; it represents a commitment to the well-being and productivity of the workforce. These helmets, with their enhanced protection against falls, impacts, and electrical shocks, not only help to prevent severe injuries but also contribute to a culture of safety that values every individual's health and contribution. The use of helmets that comply with the latest safety standards like ANSI/ISEA Z89.1-2014 (R2019) reduces the incidence of work-related head injuries, leading to fewer absences, lower medical costs, and improved morale among workers. By prioritizing the adoption of Type II safety helmets, employers invest in their most valuable asset—their employees—ensuring that they can work effectively while being adequately protected from the inherent risks of their jobs." 
        />
        {/* Add more TextSection components as needed for different sections of content */}
      </main>
    </div>
  );
};

export default Type2Helmet;

// ReactDOM.render(<HomePage />, document.getElementById('root'));
