// MainVideo.js

import React from 'react';
import PropTypes from 'prop-types'; // Don't forget to install this package

const MainVideo = ({ videoFile, videoRef=null }) => {
  return (
    <div className="video-container" style={{ margin: '2rem 0' }}>
      <video
        width="100%"
        height="auto"
        controls
        title="Scan To Understand - Construction Industry Introduction"
        key={videoFile}
        ref={videoRef}
      >
        <source src={videoFile} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

// Prop types validation
MainVideo.propTypes = {
  videoFile: PropTypes.string.isRequired,
};

export default MainVideo;
