import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import Type2Helmet from './pages/Type2Helmet';
import SafetyHarness from './pages/SafetyHarness'
import LockOutTagOut from './pages/LockOutTagOut'
import Info from './pages/Info'
import './App.css';

const App = () => {
  return (
    <Router>
      <Navigation />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
        integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
        crossorigin="anonymous"
      />
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/type-2-helmet" element={<Type2Helmet />} />
        <Route path="/safety-harness" element={<SafetyHarness />} />
        <Route path="/lock-out-tag-out" element={<LockOutTagOut />} />
        <Route path="/safety-harness.info" element={<Info request_id="db598de2-009e-4aaf-9cef-8ac29da97877" />} />
        <Route path="/lock-out-tag-out.info" element={<Info request_id="ffb9abe1-081e-432e-8946-aec70450d500" />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
