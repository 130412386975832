// Navigation.js

import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import logo from '../img/logo.png'; // Path to your logo file

const Navigation = () => {
  return (
    <Navbar>
      <Navbar.Brand >Scan To Understand</Navbar.Brand>
        {/* <Nav className="mr-auto">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#about">About</Nav.Link>
          <Nav.Link href="#industries">Industries</Nav.Link>
          <Nav.Link href="#contact">Contact</Nav.Link>
        </Nav> */}
        <Navbar.Brand className="ms-auto">
          <img
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="Scan To Understand logo"
          />
        </Navbar.Brand>
    </Navbar>
  );
};

export default Navigation;
