// Footer.js

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="footer mt-auto py-3">
      <Container>
        <Row>
          <Col md={6}>
            <span>© Scan To Understand {new Date().getFullYear()}</span>
          </Col>
          <Col md={6} className="text-md-right">
            <a href="#privacy">Privacy Policy</a> | <a href="#terms">Terms of Use</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
