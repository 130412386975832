import React from 'react';
import ReactDOM from 'react-dom';

import MainVideo from '../components/MainVideo';
import TextSection from '../components/TextSection';
// import video from '../video/type2helmet.mp4';


const SafetyHarness = () => {
  return (
    <div>
      <main role="main" className="container">
      <MainVideo videoFile={"/media/falltech_user_manual/video.mp4"} />
      <MainVideo videoFile={"https://scantounderstand.com/media/falltech_user_manual/video.mp4"} />
        <TextSection 
          title="Understanding Fall Protection: Key Concepts and Equipment"
          content="Fall protection is a critical aspect of workplace safety in environments where workers are at risk of falling from heights. The use of specialized equipment such as the FT-XTM Cable Class 2 Leading Edge SRL-P is essential for preventing injuries. This equipment complies with ANSI, CSA, and OSHA standards, ensuring that it meets rigorous safety and performance requirements. Understanding how to properly use and maintain this equipment is crucial for ensuring worker safety and compliance with regulatory standards." 
        />
        <TextSection 
          title="Safety Maintenance: Ensuring Long-term Reliability of Fall Protection Equipment"
          content="Regular maintenance and inspection are vital to ensuring the reliability and safety of fall protection equipment. The FT-XTM Cable Class 2 Leading Edge SRL-P requires thorough inspections before each use, as well as periodic maintenance by a competent person. This process includes checking for wear, damage, and proper function. By adhering to these guidelines, employers can prevent equipment failures and protect employees from potential falls and injuries." 
        />
        <TextSection 
          title="Proper Usage of Self-Retracting Lifelines in Various Work Conditions"
          content="Self-retracting lifelines (SRLs) like the FT-XTM Cable Class 2 Leading Edge SRL-P offer flexibility and protection in various work conditions, including overhead and below-the-dorsal-ring applications. Understanding the specific conditions for each type of application is key to maximizing safety. This section provides essential information on how to properly set up and use SRLs to ensure optimal performance and safety in different scenarios." 
        />
        {/* Add more TextSection components as needed for different sections of content */}
      </main>
    </div>
  );
};

export default SafetyHarness;

// ReactDOM.render(<HomePage />, document.getElementById('root'));
