import React from 'react';
import ReactDOM from 'react-dom';

import MainVideo from '../components/MainVideo';
import TextSection from '../components/TextSection';


const LockOutTagOut = () => {
  return (
    <div>
      <main role="main" className="container">
        <MainVideo videoFile={`/media/lock_out_tag_out/video.mp4`} />
        <TextSection 
          title="Securing Safety: The Essentials of Lockout/Tagout Procedures"
          content="Lockout/tagout procedures are critical safety measures in industrial settings, designed to ensure that machinery is properly shut off and unable to be started up again prior to the completion of maintenance or repair work. These procedures protect workers from the release of hazardous energy and prevent accidents and injuries. Compliance with OSHA Standard 29 CFR 1910.147 is not only a regulatory requirement but a fundamental practice to safeguard workers from potential harm caused by the unexpected energization or startup of machinery and equipment."
        />
        <TextSection 
          title="Effective Communication and Compliance: Keys to Lockout/Tagout Effectiveness"
          content="Effective lockout/tagout procedures require clear communication and strict compliance from all team members. By implementing these procedures, workers ensure that all energy sources to equipment are neutrally and securely controlled. Regular training sessions, clear labeling, and the use of standardized locks and tags are integral to maintaining a safe work environment. These practices prevent accidental machine start-up and ensure that all employees are aware of the operational status of the machinery, thereby significantly reducing workplace accidents."
        />
        <TextSection 
          title="Cultivating a Culture of Safety with Lockout/Tagout"
          content="Adopting rigorous lockout/tagout procedures reflects an organization's commitment to creating a safe working environment. This commitment helps cultivate a culture of safety that transcends the immediate benefits of hazard control. Long-term implementation of these safety measures not only prevents injuries and fatalities but also boosts worker confidence and morale. Employers who enforce these safety protocols demonstrate their investment in their workforce’s well-being, leading to improved productivity and a reduction in costly downtime and workers' compensation claims."
        />
        {/* Add more TextSection components as needed for different sections of content */}
      </main>
    </div>
  );
};

export default LockOutTagOut;

// ReactDOM.render(<HomePage />, document.getElementById('root'));
