import React from 'react';
import ReactDOM from 'react-dom';

// Components (assuming you've created these in separate files)
import Navigation from '../components/Navigation';
import MainVideo from '../components/MainVideo';
import TextSection from '../components/TextSection';
import Footer from '../components/Footer';
import video from '../video/video_output_zoomin.mp4';


const HomePage = () => {
  return (
    <div>
      <main role="main" className="container">
        <MainVideo videoFile={video} />
        <TextSection 
          title="Revolutionizing Construction with QR Codes"
          content="Scan To Understand is pioneering the integration of QR technology in the construction industry. With our QR codes, get instant access to detailed video explanations and specifications for every component of your construction project. From materials to machinery, a simple scan can provide valuable insights, ensure compliance, and streamline communication among stakeholders." 
        />
        <TextSection 
          title="Our Vision"
          content="Our vision is to enhance the accessibility of information on construction sites, making it simple and immediate to obtain knowledge about any aspect of the project. We believe that informed teams are effective teams, and our QR codes serve as the bridge between curiosity and understanding." 
        />
        {/* Add more TextSection components as needed for different sections of content */}
      </main>
    </div>
  );
};

export default HomePage;

// ReactDOM.render(<HomePage />, document.getElementById('root'));
